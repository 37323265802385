<template>
  <div id="Diagnose" class="content">
    <div class="page_title">
      <img src="../assets/images/detail/left_back.png" alt @click="goBack">
      <span>诊断信息</span>
      <van-popover
        class="diagnosePopover"
        v-model="isShowPopover"
        trigger="click"
        :get-container="getContainer"
        placement="bottom-end">
        <ul class="popoverList">
          <li @click="addDiagnoseItem">
            <div class="icon add"/>
            <span>添加诊断信息</span>
          </li>
          <li @click="toggleAllSlide(true)">
            <div class="icon slideDown"/>
            <span>展开所有</span>
          </li>
          <li @click="toggleAllSlide(false)">
            <div class="icon slideUp"/>
            <span>收起所有</span>
          </li>
        </ul>
        <template #reference>
          <div class="addBtn"/>
        </template>
      </van-popover>
    </div>
    <ul class="diagnoseList">
      <li v-for="(item, index) in diagnoseList" :key="index">
        <diagnose-com
          :diagnose-list="diagnoseList"
          :diagnose-item="item"
          :diagnose-index="index"
          :system-list="systemList"
          :solution-list="solutionList"
          :fault-part-list="faultPartList"
          @toggleSlide="toggleSlide(index)"
          @removeDiagnoseItem="showConfirmRemoveDiagnoseItemPopup(index)"
          @addMetricalDataItem="addMetricalDataItem(index)"
          @removeMetricalDataItem="removeMetricalDataItem(index, $event)"
          @addResultDataItem="addResultDataItem(index)"
          @removeResultDataItem="removeResultDataItem(index, $event)"
        />
      </li>
    </ul>

    <section class="container">
      <h3>维修建议（选填）</h3>
      <div class="item">
        <label>重大项目</label>
        <textarea v-model="suggestParams.major_projects" placeholder="请输入" />
      </div>
      <div class="item">
        <label>一般项目</label>
        <textarea v-model="suggestParams.general_items" placeholder="请输入" />
      </div>
      <div class="item">
        <label>潜在项目</label>
        <textarea v-model="suggestParams.potential_projects" placeholder="请输入" />
      </div>
    </section>

    <footer-btn
      :is-disabled="isDisabeld"
      confirm-text="提交诊断信息"
      @confirm="confirm"
    />

    <confirm-modal
      :show-popup="isShowConfirmPopup"
      :title="`是否确定删除诊断信息${Number(currentIndex) + 1}？`"
      icon="is_delete_icon"
      @closePopup="isShowConfirmPopup = false"
      @confirm="removeDiagnoseItem"
    />
    <GoHome />
  </div>
</template>

<script>
import port from '@/api/port'
import DiagnoseCom from '../components/diagnoseCom/index'
import FooterBtn from '@/components/footerBtn'
const metricalItem = {
  'name': '',
  'value': '',
  'unit': ''
}
const resultItem = {
  'solution': '',
  'description': '',
  'fault_part_dict_id': '',
  'use_part_name': '',
  'use_part_no': ''
}
const diagnoseItem = {
  isSlideUp: true,
  system_id: '',
  part_id: '',
  part_name: '',
  fault_id: '',
  fault_name: '',
  fault_code: '',
  fault_reason: '',
  photo_list: [],
  video_list: [],
}
export default {
  name: 'Diagnose',
  components: { FooterBtn, DiagnoseCom },
  data() {
    return {
      diagnoseList: [
        {
          ...diagnoseItem,
          metrical_data: [
            { ...metricalItem }
          ],
          result: [
            { ...resultItem }
          ]
        }
      ],
      suggestParams: {
        major_projects: '',
        general_items: '',
        potential_projects: ''
      },
      systemList: [],
      solutionList: [],
      faultPartList: [],
      currentIndex: 0,
      isShowConfirmPopup: false,
      isShowPopover: false
    }
  },
  computed: {
    id() {
      return this.$route.query.id
    },
    method() {
      return this.$route.query.method
    },
    isDisabeld() {
      const empty = this.diagnoseList.find((item) => {
        const keys = ['system_id', 'fault_code', 'fault_reason']
        const emptyItem = keys.find(key => {
          return !item[key]
        })
        // 选择其他时需要填写对应name
        const isEmptyPart = item.part_id === '' || (item.part_id === '0' && item.part_name === '')
        const isEmptyFault = item.fault_id === '' || (item.fault_id === '0' && item.fault_name === '')

        // 是否有未填写的测量数据
        const emptyMetricalData = item.metrical_data.find(metrical => {
          return metrical.name === '' || metrical.value === '' || metrical.unit === ''
        })
        // 是否有未填写的诊断结论
        const emptyResultItem = item.result.find(result => {
          return result.solution === ''
              || result.description === ''
              || result.fault_part_dict_id === ''
              || result.use_part_name === ''
              || result.use_part_no === ''
        })

        if(emptyItem || isEmptyPart || isEmptyFault || emptyMetricalData || emptyResultItem || item.photo_list.length === 0){
          return true
        }
      })
      return Boolean(empty)
    }
  },
  created() {
    // 系统>部位>故障内容
    port.getDict({
      type: 'fault',
      sort: '1'
    }).then(res => {
      // 只返回有三级的数据
      this.systemList = res.data.filter(item => {
        return item.children && item.children[0] && item.children[0].children
      })
    })
    // 故障部位
    port.getDict({
      type: 'part',
      sort: '1'
    }).then(res => {
      this.faultPartList = res.data
    })
    // 排除方法
    port.getDict({
      type: 'solution',
      sort: '1'
    }).then(res => {
      this.solutionList = res.data
    })

    port.getWorkDetail({
      work_id: this.id
    }).then(res => {
      const {
        major_projects = '',
        general_items = '',
        potential_projects = ''
      } = res.data
      this.suggestParams = {
        major_projects,
        general_items,
        potential_projects
      }
    })

    port.getDiagnoseList({
      work_id: this.id
    }).then(res => {
      if (res.data.length > 0) {
        this.diagnoseList = res.data.map(item => {
          const {
            id,
            system_id,
            part_dict_id,
            part_name,
            fault_id,
            fault_name,
            fault_code,
            metrical_data,
            fault_reason,
            photo_file_list,
            video_file_list,
            result
          } = item
          return {
            id,
            isSlideUp: true,
            system_id: system_id ? system_id.toString() : '',
            part_id: part_dict_id,
            part_name,
            fault_id,
            fault_name,
            fault_code,
            metrical_data,
            fault_reason,
            result,
            photo_list: photo_file_list.map(({ id, file_url }) => {
              return {
                id,
                url: file_url
              }
            }),
            video_list: video_file_list.map(({ id, file_url }) => {
              return {
                id,
                url: file_url
              }
            })
          }
        })
      }
    })
  },
  methods: {
    getContainer() {
      return document.querySelector('.page_title')
    },
    toggleAllSlide(flag) {
      this.diagnoseList.forEach(item => {
        item.isSlideUp = flag
      })
      this.isShowPopover = false
    },
    toggleSlide(index) {
      this.diagnoseList[index].isSlideUp = !this.diagnoseList[index].isSlideUp
    },
    addMetricalDataItem(index) {
      this.diagnoseList[index].metrical_data.push({
        ...metricalItem
      })
    },
    removeMetricalDataItem(index, metricalDataIndex) {
      this.diagnoseList[index].metrical_data.splice(metricalDataIndex, 1)
    },
    addResultDataItem(index) {
      this.diagnoseList[index].result.push({
        ...resultItem
      })
    },
    removeResultDataItem(index, resultIndex) {
      this.diagnoseList[index].result.splice(resultIndex, 1)
    },
    addDiagnoseItem() {
      this.diagnoseList.push({
        ...diagnoseItem,
        metrical_data: [
          { ...metricalItem }
        ],
        result: [
          { ...resultItem }
        ]
      })
      this.isShowPopover = false
      this.$toast({ message: '添加成功' })
    },
    showConfirmRemoveDiagnoseItemPopup(index) {
      this.currentIndex = index
      this.isShowConfirmPopup = true
    },
    removeDiagnoseItem(index) {
      this.diagnoseList.splice(this.currentIndex, 1)
      this.isShowConfirmPopup = false
    },
    confirm() {
      if (this.isDisabeld) return
      if (this.diagnoseList.length === 0) {
        this.$toast({ message: '请填写诊断信息' })
        return
      }
      console.log(this.diagnoseList)
      const params = this.diagnoseList.map((item, index) => {
        const photo_list = item.photo_list.map(photo => {
          return photo.id
        })
        const video_list = item.video_list.map(video => {
          return video.id
        })
        const newObj = { ...item }
        delete newObj.isSlideUp
        return {
          sort: Number(index) + 1,
          ...newObj,
          photo_list: photo_list ? photo_list.join() : '',
          video_list: video_list ? video_list.join() : ''
        }
      })
      // return
      port.submitDiagnose({
        work_id: this.id,
        params,
        ...this.suggestParams
      }, this.method).then(res => {
        this.$toast({ message: '操作成功' })
        this.$router.back()
      })
    }
  }
}
</script>
<style  lang="less">
#Diagnose{
  .page_title{
    .van-popup{
      top: .65rem!important;
      left: auto!important;
      right: .14rem;
    }
    .popoverList{
      width: 2.53rem;
      li{
        display: flex;
        align-items: center;
        line-height: .34rem;
        .icon{
          width: .24rem;
          height: .24rem;
          margin: 0 .24rem;
          background: url("../assets/images/detail/popover_01.png") no-repeat center center;
          background-size: 100%;
        }
        .slideDown{
          background-image: url("../assets/images/detail/popover_02.png");
        }
        .slideUp{
          background-image: url("../assets/images/detail/popover_03.png");
        }
        span{
          width: 1.81rem;
          padding: .17rem 0 .15rem;
          border-bottom: .01rem solid #D8D8D8;
          font-size: .24rem;
          text-align: left;
        }
      }
    }
  }
}
</style>
<style scoped lang="less">
#Diagnose {
  padding: 0.75rem 0 .84rem;
  .page_title{
    .addBtn{
      width: 0.41rem;
      height: 0.41rem;
      position: absolute;
      right: .27rem;
      top: 0.15rem;
      background: url("../assets/images/detail/add_icon.png") no-repeat center center;
      background-size: 100%;
    }
  }

  .container{
    padding: .32rem .27rem;
    background: #fff;
    h3{
      line-height: .38rem;
      font-size: .27rem;
      font-weight: 400;
      margin-bottom: .22rem;
      padding-left: .17rem;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        left: 0;
        top: .05rem;
        width: .07rem;
        height: .27rem;
        background: #2F8AF1;
      }
    }
    .item{
      display: flex;
      align-items: flex-start;
      margin-bottom: 0.14rem;
    }
    label{
      font-size: .23rem;
      font-weight: 400;
      color: #333333;
      line-height: .58rem;
      margin-right: .2rem;
      display: inline-block;
    }
    textarea {
      width: 4.69rem;
      height: 1.88rem;
      border-radius: 0.1rem;
      border: 0.02rem solid rgba(212, 212, 212, 1);
      padding: 0.2rem;
      box-sizing: border-box;
    }
  }

}
</style>
